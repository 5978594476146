// http://type-scale.com/
html { font-size: $font-size; }

body {
  font-family: $font-family;
  font-weight: $font-weight;
  line-height: 1.85;
  color: $font-color;
}

p,
.p {
  font-size: 1rem;
  margin-bottom: 1.3rem;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4 {
  margin: 1.414rem 0 .5rem;
  font-weight: inherit;
  line-height: 1.42;
}

h1,
.h1 {
  margin-top: 0;
  font-size: 3.998rem;
}

h2,
.h2 { font-size: 2.827rem; }

h3,
.h3 { font-size: 1.999rem; }

h4,
.h4 { font-size: 1.414rem; }

h5,
.h5 { font-size: 1.121rem; }

h6,
.h6 { font-size: .88rem; }

small,
.small { font-size: .707em; }

pre,
code {
  font-family: $monospace-font-family;
}

.italic { font-style: italic; }

.thin { font-weight: 100; }

.bold { font-weight: bold; }

.caps { font-variant: small-caps; }

.justify { text-align: justify; }

.ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
