//out: style.min.css, compress: true, sourceMap: false, autoprefixer: last 3 versions
@import 'thirdparty/furtive/all';

///// Variablen /////
 //Farben:
  $primary: #004F8E;
  $secondary: #D3E1EC;
  $white: #ffffff;
  $light-grey: rgb(235, 236, 237);
  $darken-secondary: #81A8C7;
  $transparent: rgba(255, 255, 255, 0);
  $transparent-white: rgba(255, 255, 255, 0.1);
  $transparent-primary: rgba(0, 79, 142, 0.2);
 // Margins / Paddings:
 $content-margin: 72px;
 $swiper-bullet-margin: 0 7px;
 // assets
 $arrow-right-white: 'data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 55.43 16.57"><defs><style>.cls-1{fill:rgba(255, 255, 255, 1);}</style></defs><title>arrow</title><g id="Ebene_2" data-name="Ebene 2"><g id="Ebene_1-2" data-name="Ebene 1"><rect class="cls-1" x="50.7" y="-1.35" width="0.87" height="11.28" transform="translate(11.94 37.42) rotate(-45)"/><rect class="cls-1" x="50.09" y="6.63" width="0.87" height="11.28" transform="translate(77.57 56.67) rotate(-135)"/><rect class="cls-1" y="7.78" width="54" height="1"/></g></g></svg>';
 $arrow-left-white: 'data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 55.43 16.57"><defs><style>.cls-1{fill:rgba(255, 255, 255, 1);}</style></defs><title>arrow-prev</title><g id="Ebene_2" data-name="Ebene 2"><g id="Ebene_1-2" data-name="Ebene 1"><rect class="cls-1" x="3.86" y="-1.35" width="0.87" height="11.28" transform="translate(4.29 10.37) rotate(-135)"/><rect class="cls-1" x="4.47" y="6.63" width="0.87" height="11.28" transform="translate(-7.24 7.06) rotate(-45)"/><rect class="cls-1" x="1.43" y="7.78" width="54" height="1" transform="translate(56.86 16.57) rotate(180)"/></g></g></svg>';
 $arrow-right-primary: 'data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 55.43 16.57"><defs><style>.cls-1{fill:rgba(0, 79, 142, 1);}</style></defs><title>arrow</title><g id="Ebene_2" data-name="Ebene 2"><g id="Ebene_1-2" data-name="Ebene 1"><rect class="cls-1" x="50.7" y="-1.35" width="0.87" height="11.28" transform="translate(11.94 37.42) rotate(-45)"/><rect class="cls-1" x="50.09" y="6.63" width="0.87" height="11.28" transform="translate(77.57 56.67) rotate(-135)"/><rect class="cls-1" y="7.78" width="54" height="1"/></g></g></svg>';
 $arrow-left-primary: 'data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 55.43 16.57"><defs><style>.cls-1{fill:rgba(0, 79, 142, 1);}</style></defs><title>arrow-prev</title><g id="Ebene_2" data-name="Ebene 2"><g id="Ebene_1-2" data-name="Ebene 1"><rect class="cls-1" x="3.86" y="-1.35" width="0.87" height="11.28" transform="translate(4.29 10.37) rotate(-135)"/><rect class="cls-1" x="4.47" y="6.63" width="0.87" height="11.28" transform="translate(-7.24 7.06) rotate(-45)"/><rect class="cls-1" x="1.43" y="7.78" width="54" height="1" transform="translate(56.86 16.57) rotate(180)"/></g></g></svg>';


 // Navigation
 $nav-transition-time: 0.3s;
 $nav-height-mobile: 55px;
 // Font
 $line-height-p: 1.5;
///// Hamburger Menu /////
$hamburger-padding-x: 15px !default;
$hamburger-padding-y: 25px;
$hamburger-layer-width: 30px;
$hamburger-layer-height: 5px;
$hamburger-layer-spacing: 6px !default;
$hamburger-layer-color: $white;
$hamburger-layer-border-radius: 0px;
$hamburger-hover-opacity: 1;
$hamburger-hover-transition-duration: 0.15s !default;
$hamburger-hover-transition-timing-function: linear !default;
@import "thirdparty/hamburgers-master/_sass/hamburgers/hamburgers";

// Util
@function calculateRem($size) {
  $remSize: $size / 16px;
  @return #{$remSize}rem;
}

@mixin fontSize($size) {
  font-size: $size; //Fallback in px
  font-size: calculateRem($size);
}
///// Testing /////
// a[href=""], a:not([href]) {
//   outline: 5px solid red;
// }
// a[title=""], a:not([title]) {
//   outline: 5px solid red;
// }
img[alt=""], img:not([alt]) {
  outline: 0;
}
///// Defaults /////
form input.error,form textarea.error{
  border:1px solid #ca1a23
}
.font-right{
  text-align: right;
}
.font--bold{
  font-weight:700;
}
.p1--lr{
  padding-left: 1rem;
  padding-right: 1rem;
}
.color--error{
  color:#ca1a23;
  border:1px solid #ca1a23;
  padding:.5rem;
}
.color--success{
  color:#0f8623;
  border:1px solid #0f8623;
  padding:.5rem;
}

.measure--sm {
  max-width: 48rem;
  margin: 0 auto;
}

.measure--md {
  max-width: 64rem;
  margin: 0 auto;
}

.measure--lg {
  max-width: 90rem;
  margin: 0 auto;
}
.form-v1 {
  background: #E2E2E2;
}

.form-v1 label {
  margin: .3rem 0;
  display: block;
}

.form-v1 .form-field.select .select-style {
  overflow: hidden;
  position: relative;
  margin-bottom: .5rem;
  background: #fff;
  display: inline-block;
}

.form-v1 .form-field.select .select-style select {
  padding: .5rem 2.7rem .5rem .5rem;
  margin: 0;
  width: 130%;
  border: none;
  box-shadow: none;
  background: transparent;
  background-image: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.form-v1 .form-field.select .select-style select:focus {
  outline: none;
}

.form-v1 .form-field.select .select-style::after {
  content: '';
  pointer-events: none;
  display: block;
  position: absolute;
  height: 100%;
  width: 50px;
  top: 0;
  right: 0;
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9.97 5.27'%3E%3Cdefs%3E%3Cstyle%3E.a%7Bfill:%23707070;%7D%3C/style%3E%3C/defs%3E%3Ctitle%3Eicon-arrow-gray-down%3C/title%3E%3Cpolygon class='a' points='4.99 5.27 0 0.74 0.67 0 4.99 3.92 9.3 0 9.97 0.74 4.99 5.27'/%3E%3C/svg%3E") center center/11px 6px no-repeat;
}



.form-v1 a {
  color: #f0a500;
}

.form-v1 a.button,
.form-v1 a.button--solid,
.form-v1 a.button--pill,
.form-v1 a.button--outline,
.form-v1 a.button--pill-outline {
  color: #fff;
}

.form-v1 .button,
.form-v1 .button--solid,
.form-v1 .button--pill,
.form-v1 .button--outline,
.form-v1 .button--pill-outline {
  padding: .3rem 1rem;
  border-radius: 6px;
  font-size: .8rem;
}

.form-v1 .pretty .state label:before {
  background-color: #fff;
  border-color: transparent;
}

.form-v1 .pretty.p-icon input:checked ~ .state .icon {
  color: #f0a500;
}

.form-v1 .pretty.p-icon input:checked ~ .state label:before {
  border-color: transparent;
}
.bg-img-container {
  min-height:1px !important;
}
body, html {
  -webkit-hyphens: auto;
      -ms-hyphens: auto;
          hyphens: auto;
  font-size: 17px;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}
a {
  text-decoration: none;
  -webkit-transition: 0.1s;
  transition: 0.1s;
  &:hover {
    color: $primary;
    -webkit-transition: 0.1s;
    transition: 0.1s;
  }
  &:active {
    color: $primary;
  }
  &.neutralize {
    color: $primary;
    text-decoration: underline;
    font-weight: bold;
    &:hover {
      color: $primary;
    }
    &:active {
      color: $primary;
    }
  }
}
h1, h2, h3, h4, h5, h6  {
  color: $primary;
  font-weight: bold;
}
p {
  color: $primary;
  font-size: 17px;
}
ol {
  color: $primary;
  margin: 0 0 1.5em;
  padding: 0;
  counter-reset: item;
  & > li {
    margin: 0;
    padding: 0 0 0 1rem;
    text-indent: -1rem;
    list-style-type: none;
    counter-increment: item;
  }

  & > li:before {
    display: inline-block;
    width: 1em;
    padding-right: 0.5em;
    font-weight: bold;
    text-align: right;
    content: counter(item) ".";
  }
}
header {
  margin-top: 78px;
}
main {
  margin-top: $nav-height-mobile;
  display: block;
  &.site-with-header {
    margin-top: 0;
  }
}
hr {
  border-bottom: 1px solid $secondary;
}
address {
  font-style: normal;
  font-size: 17px;
  color: $primary;
  .address-ul {
    font-size: 17px;
    margin-bottom: 1.3rem;
  }
}
.mfp-counter {
  display: none;
}
.mfp-title {
  display: none;
}
.services-slide {
  background-color: $white;
  height: auto;
  @media (min-width: $viewport-l){
    background-color: #EBECED;
  }
}
.list-bullets {
  li {
    color: $primary;
    background: url("../img/assets/list-bullet-square.svg") no-repeat left 11px;
    padding-left: 12px;
    background-size: 5px 5px;
    a {
      color: $primary;
    }
  }
}
.checklist {
  &.std-padding {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  li {
    position: relative;
    padding-top: 7px;
    padding-bottom: 7px;
    padding-left: 30px;
    font-size: 22px;
    color: $primary;
    font-weight: bold;
    &:before {
      content: "";
      position: absolute;
      left: 10px;
      top: 14px;
      display: inline-block;
      border-bottom: 2px solid $primary;
      border-right: 2px solid $primary;
      height: 19px;
      width: 8px;
      -webkit-transform: rotate(35deg);
              transform: rotate(35deg);
    }
  }
}
///// Helper Klassen /////
$spaceamounts: (0, 0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4); // Adjust this to include the pixel amounts you need.
$sides: (top, bottom, left, right); // Leave this variable alone

@each $space in $spaceamounts {
  @each $side in $sides {
    .m-#{str-slice($side, 0, 1)}-#{$space} {
      margin-#{$side}: #{$space}em !important;
    }

    .p-#{str-slice($side, 0, 1)}-#{$space} {
      padding-#{$side}: #{$space}em !important;
    }
  }
}

.display {
  &--block {
    display: block;
  }
}
.order-1 {
  -webkit-box-ordinal-group: 2;
      -ms-flex-order: 1;
          order: 1;
}
.order-2 {
  -webkit-box-ordinal-group: 3;
      -ms-flex-order: 2;
          order: 2;
}
.center-img {
  display:block;
  margin:auto;
}
.space-around-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
}
.flex-anchor {
  -ms-flex-negative: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  min-height: 1px !important;
  height: 100%;
  @media (min-width: $viewport-l){
    display: initial;
  }
}
.flex-center {
  display: flex;
  height: 100%;
  &.center-vertical {
    align-items: center;
  }
  &.center-horizontal {
    justify-content: center;
  }
  &.background-blue {
    background-color: $primary;
  }
}
.measure {
  max-width: 1205px;
}
.hide-on-mobile {
  @media (max-width: $viewport-l){
    display: none;
  }
}
.media-br {
  display: none;
  &.mobile-show {
    display: block;
  }
  &.mobile-hide {
    display: none;
  }
  @media (min-width: $viewport-m){
    &.tablet-show {
      display: block;
    }
    &.tablet-hide {
      display: none;
    }
  }
}
.text-primary {
  color: $primary;
}
.font-bold {
  font-weight: bold;
}
.font-demi-bold {
  font-weight: 600;
}
.uppercase {
  text-transform: uppercase;
}
.hide-on-mobile {
  display: none;
  @media (min-width: $viewport-l){
    display: initial;
  }
}
.clear-top {
  margin-top: 9rem;
  h1 {
    padding: 0 2rem 2rem 2rem;
  }
}
.no-wrap {
  white-space: nowrap;
}
.full-width {
  width: 100%;
}
#drehen-txt-en {
  @media (min-width: $viewport-l){
    width: 42%;
  }
}
///// JS Klassen /////
.js-active-tile {
  background-color: $white;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  &:after {
    border-top: 1px solid $primary !important;
    border-right: 1px solid $primary !important;
  }
  .img {
    .cls-1 {
      fill: $primary;
    }
  }
  .anchor {
    color: $primary !important;
  }

}
.anchor-arrow {
  &::before {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    -webkit-transition: 0.2s;
    transition: 0.2s;
    display: inline-block;
    @media (min-width: $viewport-l){
      display: none;
    }
  }
}
.js-menu-open {
    .anchor-arrow {
      &:before {
        -webkit-transform: rotate(90deg);
                transform: rotate(90deg);
        -webkit-transition: 0.2s;
        transition: 0.2s;
      }
    }
}
.js-sticky-nav {
  height: 51px;
  -webkit-transition: height $nav-transition-time;
  transition: height $nav-transition-time;
  #lang-picker {
    right: 12px;
  }
  .logo {
    width: 62px;
    height: 51px;
    padding: 5px 13px;
    -webkit-transition: all $nav-transition-time;
    transition: all $nav-transition-time;
    -webkit-transform-origin: center;
            transform-origin: center;
    @media (min-width: $viewport-xl){
      .mobile{
        display: block;
      }
      .desktop {
        display: none;
      }
      #main-navigation {
        padding-right: 0 !important;
        margin-left: 0;
      }
    }
  }
  .menu-wrapper {
    width: 100%;
    #main-navigation {
      margin-left: 14%;
      padding-right: 112px;
      @media (min-width: $viewport-xl){
        margin: 0 auto;
        left: 0;
        right: 0;
        padding-right: 0px;
      }
      -webkit-transition: all $nav-transition-time;
      transition: all $nav-transition-time;
      li:not(:last-child) {
        -webkit-transition: all $nav-transition-time;
        transition: all $nav-transition-time;
        margin-right: 20px;
        @media (min-width: $viewport-xl){
          margin-right: 43px;
        }
        // @media (max-width: $viewport-xl){
        //   margin-right: 30px;
        // }
      }
    }
    #lang-picker {
      top: 31px;
      padding-right: 16px;
      -webkit-transition: all $nav-transition-time;
      transition: all $nav-transition-time;
    }
  }
}
///// Mixins /////
@mixin centerSwiperButtons {
  position: absolute;
  height: 162px;
  width: 162px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  z-index: 1;
}
@mixin screenEffect($opacity) {
  opacity: $opacity;
  // background-image: -webkit-gradient(linear, left bottom, left top, color-stop(12.50%, rgba(255, 255, 255, 0)), color-stop(12.50%, rgba(255, 255, 255, 0)), color-stop(50%, rgba(255, 255, 255, 0)), color-stop(50%, #ffffff), color-stop(62.50%, #ffffff), color-stop(62.50%, rgba(255, 255, 255, 0)), to(rgba(255, 255, 255, 0)));
  // background-image: linear-gradient(0deg, rgba(255, 255, 255, 0) 12.50%, rgba(255, 255, 255, 0) 12.50%, rgba(255, 255, 255, 0) 50%, #ffffff 50%, #ffffff 62.50%, rgba(255, 255, 255, 0) 62.50%, rgba(255, 255, 255, 0) 100%);
  background-image:  url("../img/assets/screeneffect-fallback-img.png");
  background-repeat: repeat;
  background-size: 1px 4px;
}
@mixin scrollToAnchorButton($height, $width) {
  height: 48px;
  width: 48px;
  background-color: $white;
  border-radius: 50%;
  border: 1px solid $primary;
  outline: 0;
  &:after {
    content: "";
    position: absolute;
    top: 13px;
    left: 0;
    right: 0;
    margin: 0 auto;
    height: $height;
    width: $width;
    display: inline-block;
    background-image: url("../img/assets/arrow-down-blue-fallback.png");
    -webkit-transition: 0.1s ease-in-out;
    transition: 0.1s ease-in-out;
    @supports (color: black) {
      background-image: url("../img/assets/arrow-down-blue.svg");
    }
  }
  &:hover {
    &:after {
      -webkit-transform: translateY(2px);
              transform: translateY(2px);
      -webkit-transition: 0.3s ease-in-out;
      transition: 0.3s ease-in-out;
    }
  }
}
@mixin infoButton($bg-color, $txt-color, $arrow-color) {
  position: relative;
  height: 30px;
  padding: 0 2rem 0 1rem;
  background: $bg-color;
  color: $txt-color;
  font-size: 12px;
  font-weight: 600;
  line-height: 2.5;
  text-transform: uppercase;
  &:after {
    content: "";
    height: 1px;
    width: 1rem;
    position: absolute;
    top: 14px;
    background-color: $arrow-color;
    margin-left: 5px;
    -webkit-transition: 0.1s ease-in-out;
    transition: 0.1s ease-in-out;
  }
  &:before {
    content: "";
    height: 8px;
    width: 8px;
    position: absolute;
    border-top: 1px solid $arrow-color;
    border-left: 1px solid $arrow-color;
    -webkit-transform: rotate(135deg);
            transform: rotate(135deg);
    right: 12px;
    top: 10px;
    -webkit-transition: 0.1s ease-in-out;
    transition: 0.1s ease-in-out;
  }
  &:hover {
    line-height: 2.5;
    &:before {
      -webkit-transform: translateX(3px) rotate(135deg);
              transform: translateX(3px) rotate(135deg);
      -webkit-transition: 0.3s ease-in-out;
      transition: 0.3s ease-in-out;
    }
    &:after {
      -webkit-transform: translateX(0.2rem);
              transform: translateX(0.2rem);
      -webkit-transition: 0.3s ease-in-out;
      transition: 0.3s ease-in-out;
    }
  }
}
@mixin swiperButton($size, $border-color, $bg-pos, $arrow, $fallback-arrow, $bg-fill) {
  height: $size;
  width: $size;
  border-radius: 50%;
  border: 1px solid $border-color;
  background: url($fallback-arrow);
  @supports (color: black){
    background-image: url($arrow);
  }
  background-repeat: no-repeat;
  background-position: center;
  background-size: 55px 17px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  cursor: pointer;
  background-color: $bg-fill;
  &:hover {
    -webkit-transition: 0.3s;
    transition: 0.3s;
    background-position: $bg-pos;
  }
}
@mixin primaryGradient {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#1b629a+0,004f8e+61 */
  background: rgb(27,98,154); /* Old browsers */ /* FF3.6-15 */ /* Chrome10-25,Safari5.1-6 */
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(27,98,154,1)),color-stop(61%, rgba(0,79,142,1)));
  background: linear-gradient(to bottom, rgba(27,98,154,1) 0%,rgba(0,79,142,1) 61%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}
@mixin transperantPrimaryGradient($rgba, $position){
  height: 101px;
  width: 100%;
  background: rgb(2,0,36);
  background: -webkit-gradient(linear, left bottom, left top, from(rgba(2,0,36,0)), color-stop($position, $rgba));
  background: linear-gradient(0deg, rgba(2,0,36,0) 0%, $rgba $position);
}
@mixin enlargeIcon{
  height: 26px;
  width: 26px;
  bottom: 15px;
  right: 15px;
  background-image: url("../img/assets/icon-vergroessern-fallback.png");
  @supports (color: black){
    background-image: url("../img/assets/icon-vergroessern.svg");
  }
}
///// Lightbox /////
button.mfp-arrow {
  @include swiperButton(70px, $white !important, 58%, $arrow-right-white, "../img/assets/arrow-right-white-fallback.png", $transparent);
  background-size: 31px 22px;
  margin-top: -55px;
  @media (max-width: 900px){
    transform: scale(1);
  }
}
.mfp-zoom-out-cur {
  overflow: hidden;
}
.mfp-arrow {
  &:before, &:after {
    display: none;
  }
}
.mfp-arrow-right {
  right: 1rem;
  transform: translateY(50%);
  @media (max-width: 900px){
    transform: scale(0.75) translateY(50%) !important;
  }
}
.mfp-arrow-left {
  -webkit-transform: rotate(180deg) translateY(-50%) !important;
          transform: rotate(180deg) translateY(-50%) !important;
  left: 1rem;
  @media (max-width: 900px){
    transform-origin: 51%;
    transform: scale(0.75) rotate(180deg) translateY(-50%) !important;
  }
}
///// Navigation /////
.menu-open {
  overflow: hidden !important;
  @supports (-webkit-overflow-scrolling: touch) { //if ios mobile
    position: fixed;
  }
}
nav {
  position: fixed;
  top: 0;
  background-color: $primary;
  width: 100vw;
  // height: $nav-height-mobile;
  z-index: 100;
  @media (min-width: $viewport-xl){
    -webkit-transition: height $nav-transition-time;
    transition: height $nav-transition-time;
  }
  .outer {
    position: relative;
    height: $nav-height-mobile;
    .logo {
      z-index: 10000;
    }
  }
  .inner {
    position: relative;
    background-color: $primary;
    padding-bottom: 100vh;
    display: none;
    .menu-wrapper {
      height: calc(100vh - 109px);
      overflow: auto;
    }
    #main-navigation {
      padding-top: 35px;
      padding-left: 40px;
      .contain-sub-menu {
        &:before {
          -webkit-transition: 0.3s;
          transition: 0.3s;
        }
      }
      li {
        &:not(:first-child) {
          margin-top: 30px;
        }
        a {
          font-size: 19px;
          &.anchor-arrow {
            &:before {
              content: "";
              margin-right: 6px;
              height: 11px;
              width: 19px;
              background-image: url("../img/assets/nav-arrow-right-fallback.png");
              background-repeat: no-repeat;
              @supports (color: black) {
                background-image: url("../img/assets/nav-arrow-right.svg");
              }
            }
          }
        }
      }
      .sub-menu {
        padding-left: 32px;
        padding-top: 32px;
        display: none;
        a {
          padding: 0;
          &:hover {
            color: $white;
            -webkit-transition: color 0.1s;
            transition: color 0.1s;
          }
        }
        li {
          &:not(:first-child) {
            margin-top: 10px;
          }
          &:before {
            display: none;
          }
        }
      }
    }
    ul {
      padding-top: 40px;
      padding-left: 40px;
      li {
        a {
          text-transform: uppercase;
          color: $white;
        }
      }
    }
  }
  .hamburger {
    position: absolute;
    outline: none;
    right: 0;
    padding: 15px;
    padding-top: 15px;
    padding-bottom: 0;
  }
  #lang-picker {
    font-size: 0.875rem;
    @media (min-width: $viewport-xl) {
      -webkit-transition: all $nav-transition-time;
      transition: all $nav-transition-time;
    }
    li {
      display: inline-block;
      line-height: 1.3;
      &:first-child {
        border-right: 1px solid $white;
        padding-right: 10px;
      }
      &:nth-child(2) {
        padding-left: 8px;
      }
      a {
        color: $secondary;
        &.active-lang {
          color: $white;
        }
      }
    }
  }
  .logo {
    position: absolute;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    background-color: white;
    height: $nav-height-mobile;
    width: 100px;
    @media (min-width: $viewport-xl){
      -webkit-transition: all $nav-transition-time;
      transition: all $nav-transition-time;
    }
    .desktop {
      display: none;
    }
    .mobile {
      display: inline-block;
      height: 45px;
      width: 45px;
      @media (min-width: $viewport-l){
        height: 55px;
        width: 55px;
      }
    }
  }
}
///// Unterseiten Header //////
.main-header {
  position: relative;
  max-height: 510px;
  min-height: 235px;
  overflow: hidden;
  .measure {
    padding: 30px;
    // height: auto;
    height: 100%;
    width: 100%;
    left: 0;
    right: 0;
    margin: 0 auto;
    .text-container {
      h1 {
        @media (min-width: $viewport-l){
          font-size: 4rem;
        }
        @media (min-width: 915px){
          font-size: 5.3125rem;
        }
      }
    }
    img {
      position: absolute;
      z-index: 3;
      top: 2rem;
      height: 2rem;
      width: auto;
      &#icon-verchromen {
        top: 2rem;
        height: 2rem;
      }
      &#icon-drehen {
        top: 19px;
        left: 19px;
        height: 47px;
      }
      &#icon-service {
        height: 40px;
        top: 25px;
      }
      &#icon-schleifen {
        height: 42px;
        width: 67px;
        top: 33px;
        left: 17px;
      }
    }
    h1 {
      color: $primary;
      text-transform: uppercase;
      z-index: 3;
      font-size: 30px;
      padding-left: 4rem;
      &.finishing {
        padding-left: 6rem;
      }
      &.service {
        padding-left: 4rem;
      }
    }
    p {
      -webkit-hyphens: auto;
          -ms-hyphens: auto;
              hyphens: auto;
      padding-right: 30px;
      top: 3.5rem;
      z-index: 3;
      line-height: 1.5;
      margin-bottom: 0;
    }
  }
  .gradient {
    position: absolute;
    display: none;
    top: 0;
    @media(min-width: $viewport-l){
      display: block;
    }
    @media(min-width: 1920px){
      display: none;
    }
    // background: -webkit-gradient(linear, right top, left top, from(rgba(2,0,36,0)), color-stop(32%, rgba(235,236,237,1)));
    // background: linear-gradient(270deg, rgba(2,0,36,0) 0%, rgba(235,236,237,1) 32%);
    height: 100%;
    // width: 70%;
    // background: linear-gradient(270deg, rgba(2, 0, 36, 0) 10%, #ebeced 50%);
    background: transparent url("/standards/img/assets/background-gradient.png") left top / contain repeat-y;
    width: 130%;
  }
  picture {
    right: 0;
    left: 0;
    img {
      padding: 0 30px;
      display: block;
    }/*
    &#schleifen-header {
      top: -50px;
      right: -40px;
      @media (min-width: $viewport-l){
        top: -80px;
      }
      @media (min-width: $viewport-xl){
        top: -129px;
      }
      @media (min-width: $viewport-xxl){
        top: -203px;
      }
      @media (min-width: 1920px){
        top: 0;
      }
    }
    &#verchromen-header {
      top: -50px;
      @media (min-width: $viewport-l){
        top: -80px;
      }
      @media (min-width: $viewport-xl){
        top: -129px;
      }
      @media (min-width: $viewport-xxl){
        top: -235px;
      }
    }*/
    &#schleifen-header, &#verchromen-header {
      top: 50%;
      transform: translate(0, -50%);
    }
    &#vernickeln-header {
      top: 50%;
      transform: translate(0, -50%);
      img {
        @media (min-width: $viewport-l){
          right: -25%;
        }
        @media (min-width: $viewport-xxl){
          right: 0;
        }
      }
    }
    &#verchromen-header {
      img {
        @media (min-width: $viewport-l){
          right: -30%;
        }
        @media (min-width: 1200px){
          right: -20%;
        }
        @media (min-width: $viewport-xxl){
          right: 0;
        }
      }
    }
    &#header-service {
      img {
        @media (min-width: $viewport-l){
          right: -20%;
        }
        @media (min-width: $viewport-xxl){
          right: 0;
        }
      }
    }
  }
}
.img-grd {
  .grd-row {
    & > div:not(.max-width-100) {
      max-width: 50%;
    }
    img {
      display: block;
      width: 100%;
    }
    .img-grd-txt {
      position: relative;
      line-height: 1;
      font-weight: bold;
      font-size: 10vw;
      margin: 4rem;
      text-transform: uppercase;
      &::before {
        content: "";
        position: absolute;
        height: 14px;
        width: 14px;
        border-top: 1px solid $primary;
        border-right: 1px solid $primary;
        bottom: -13px;
        left: -27px;
      }
    }
    #beste-aussichten {
      font-size: 10vw;
      @media (min-width: $viewport-l){
        font-size: 4vw;
        margin: 0;
      }
    }
    #best-opportunities {
      margin: 0;
      margin-top: 4rem;
      margin-bottom: 4rem;
      @media (min-width: $viewport-l){
        font-size: 3vw;
        margin: 0;
      }
      @media (min-width: 1920px){
        font-size: 4vw;
      }
    }
  }
  .measure {
    padding: 77px 0 78px 0;
    position: relative;
    @media (max-width: 1205px){
      padding: 35px 28px 28px 35px;
    }
    h1 {
      font-size: 31px;
    }
    p {
      font-size: 18px;
      line-height: $line-height-p;
    }
    .scroll-to-anchor {
      position: absolute;
      @include scrollToAnchorButton(20px, 12px);
      bottom: -21px;
      z-index: 3;
      display: inline-block;
      @media (max-width: $viewport-l){
        display: none;
      }
      &.center {
        left: 0;
        right: 0;
        margin: 0 auto;
      }
    }
  }
  .max-width-100 {
    &.gradient {
      position: relative;
      &:before{
        content: "";
        width: 100%;
        height: 107px;
        position: absolute;
        background: -webkit-gradient(linear, left bottom, left top, from(rgba(2,0,36,0)), to(rgba(0,79,142,0.07)));
        background: linear-gradient(0deg, rgba(2,0,36,0) 0%, rgba(0,79,142,0.07) 100%);
      }
    }
  }
  .icon-enlarge {
    position: relative;
    .responsive-img {
      display: none;
    }
    .standard-img {
      display: block;
    }
    @media (min-width: $viewport-l) and (max-width: 961px){
      .standard-img {
        display: none;
      }
      .responsive-img {
        display: block;
      }
    }
    &:after {
      content: "";
      position: absolute;
      @include enlargeIcon;
    }
  }
  .checklist-wrapper {
    background-color: $primary;
    // height: 100%;
    padding: 2rem;
    h3 {
      margin: 0;
      font-size: 28px;
      color: $white;
      text-transform: uppercase;
    }
    .checklist {
      li {
        font-size: 22px;
        line-height: 1.5;
        color: $white;
        padding-left: 35px;
        font-weight: normal;
        &:before {
          border-bottom: 2px solid $white;
          border-right: 2px solid $white;
        }
      }
    }
    @media (min-width: $viewport-l){
      padding: 1rem;
      h3 {
        font-size: 19px;
      }
      .checklist {
        li {
          font-size: 17px;
          padding-top: 3px;
          padding-bottom: 3px;
        }
      }
    }
    @media (min-width: $viewport-l) and (max-width: 792px){
      .display--block {
        display: inline-block;
      }
    }
    @media (min-width: 1535px){
      padding: 2rem;
      h3 {
        font-size: 28px;
      }
      .checklist {
        li {
          font-size: 22px;
          padding-top: 7px;
          padding-bottom: 7px;
        }
      }
    }
    @media (min-width: 1920px){
      padding-left: 85px;
      padding-top: 44px;
      h3 {
        padding-bottom: 15px;
      }
      li {
        padding-top: 10px;
        padding-bottom: 10px;
      }
    }
  }
}
///// Hero Slider /////
.hero-swiper-container {
  width: 100%;
  height: 55vw;
  overflow: hidden;
  position: relative;
  .slider-bg {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    z-index: 3;
    @include screenEffect(0.2);
  }
  .hero-swiper-button-next, .hero-swiper-button-prev {
    @include centerSwiperButtons;
    z-index: 2;
  }
  .hero-swiper-button-next {
    display: none;
  }
  .hero-swiper-button-prev {
    display: none;
  }
  .hero-swiper-pagination {
    display: block;
    position: absolute;
    bottom: 0;
    z-index: 1;
    text-align: center;
    padding: 10px;
    z-index: 10;
    @media (min-width: $viewport-xl){
      bottom: 200px;
    }
    .swiper-pagination-bullet {
      border-radius: 0;
      background-color: $darken-secondary;
      opacity: 1;
      margin: $swiper-bullet-margin;
    }
    .swiper-pagination-bullet-active {
      background-color: $white;
    }
  }
  .swiper-wrapper {
    .swiper-slide {
      .measure {
        .text-container {
          position: absolute;
          padding: 31px;
          left: 0;
          top: 0;
          height: 83%;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          h1 {
            font-size: 22px;
            color: $white;
            text-transform: uppercase;
            margin-bottom: 0;
            line-height: 1;
            @media (min-width: 365px){
              font-size: 26px;
            }
          }
          p {
            margin-top: 12px;
            color: $white;
            line-height: 1.2;
          }
        }
      }
      img {
        -o-object-fit: cover;
           object-fit: cover;
        -o-object-position: center;
           object-position: center;
        font-family: 'object-fit: cover; object-position: center;';
        height: 100%;
        width: 100%;
      }
    }
  }
}
///// Services Slider /////
#service-slider-intro-text {
  max-width: 100%;
  @media (min-width: $viewport-m){
    max-width: 72%;
  }
}
.services-main-container {
  position: relative;
  .services-swiper-container {
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    position: static;
    .swiper-slide {
      overflow: hidden;
      -webkit-hyphens: auto;
          -ms-hyphens: auto;
              hyphens: auto;
      #finish-text {
        width: 100%;
        @media (min-width: $viewport-l){
          width: 54%;
        }
      }
      &.compat-object-fit {
        background-size: 320px 176px;
        background-repeat: no-repeat;
      }
      .flex-anchor {
        &:before {
          content: "";
          display: inline-block;
          height: 166px;
          width: 100%; /* FF3.6-15 */ /* Chrome10-25,Safari5.1-6 */
          background: -webkit-gradient(linear, left top, left bottom, color-stop(20%, rgba(235,236,237,1)),to(rgba(229,229,229,0)));
          background: linear-gradient(to bottom, rgba(235,236,237,1) 20%,rgba(229,229,229,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
          position: absolute;
          top: 0;
          @media (min-width: $viewport-l){
            display: none;
          }
        }
      }
      h3 {
        font-size: 31px;
        line-height: 1;
        position: absolute;
        top: 0;
        padding-right: 30px;
      }
      .measure-outer {
        max-width: 1205px;
        .measure {
          padding: 0 30px 0 30px;
          .text-container {
            .button-wrapper {
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              -webkit-box-pack: center;
                  -ms-flex-pack: center;
                      justify-content: center;
              margin-bottom: 2rem;
              .more-infos-button {
                @include infoButton($primary, $white, $white);
                padding-top: 7px;
                padding-bottom: 7px;
                line-height: 1.5 !important;
              }
            }
            h3 {
              text-transform: uppercase;
              &.add-icon {
                position: absolute;
                @media (min-width: $viewport-l){
                  position: relative;
                }
                &.schleifen {
                  padding-left: 70px;
                  @media (min-width: $viewport-l){
                    padding-left: 135px;
                  }
                  &:before {
                    content: "";
                    position: absolute;
                    display: inline-block;
                    background-repeat: no-repeat;
                    height: 41px;
                    width: 110px;
                    left: -32px;
                    top: 0;
                    margin-left: 1rem;
                    background-image: url("../img/assets/icon-schleifen-blau-fallback.png");
                    background-size: contain;
                    @supports (color: black){
                      background-image: url("../img/assets/icon-schleifen-blau.svg");
                    }
                    @media (min-width: $viewport-l){
                      height: 90px;
                      width: 145px;
                      left: -32px;
                      top: -23px;
                      margin-left: 0;
                    }
                  }
                }
                &.verchromen {
                  padding-left: 60px;
                  margin-top: 2rem;
                  @media (min-width: $viewport-l){
                    padding-left: 108px;
                  }
                  &:before {
                    content: "";
                    position: absolute;
                    display: inline-block;
                    background-repeat: no-repeat;
                    height: 33px;
                    width: 92px;
                    left: -32px;
                    top: -4px;
                    margin-left: 2rem;
                    background-image: url("../img/assets/icon-verchromen-blau-fallback.png");
                    background-size: contain;
                    @supports (color: black){
                      background-image: url("../img/assets/icon-verchromen-blau.svg");
                    }
                    @media (min-width: $viewport-l){
                      height: 64px;
                      width: 87px;
                      left: 0;
                      top: -23px;
                      margin-left: 0;
                    }
                  }
                }
                &.vernickeln {
                  padding-left: 60px;
                  margin-top: 2rem;
                  @media (min-width: $viewport-l){
                    padding-left: 108px;
                  }
                  &:before {
                    content: "";
                    position: absolute;
                    display: inline-block;
                    background-repeat: no-repeat;
                    height: 34px;
                    width: 92px;
                    left: -32px;
                    top: -4px;
                    margin-left: 2rem;
                    background-image: url("../img/assets/icon-vernickeln-blau-fallback.png");
                    background-size: contain;
                    @supports (color: black){
                      background-image: url("../img/assets/icon-vernickeln-blau.svg");
                    }
                    @media (min-width: $viewport-l){
                      height: 64px;
                      width: 87px;
                      left: 0;
                      top: -10px;
                      margin-left: 0;
                    }
                  }
                }
                &.finishing {
                  padding-left: 83px;
                  margin-top: 2rem;
                  @media (min-width: $viewport-l){
                    padding-left: 126px;
                  }
                  &:before {
                    content: "";
                    position: absolute;
                    display: inline-block;
                    background-repeat: no-repeat;
                    height: 34px;
                    width: 92px;
                    left: -32px;
                    top: -4px;
                    margin-left: 2rem;
                    background-image: url("../img/assets/icon-finishing-blau-fallback.png");
                    background-size: contain;
                    @supports (color: black){
                      background-image: url("../img/assets/icon-finishing-blau.svg");
                    }
                    @media (min-width: $viewport-l){
                      height: 61px;
                      width: 115px;
                      left: -18px;
                      top: -10px;
                      margin-left: 0;
                    }
                  }
                }
                &.service {
                  padding-left: 60px;
                  margin-top: 2rem;
                  @media (min-width: $viewport-l){
                    padding-left: 120px;
                  }
                  &:before {
                    content: "";
                    position: absolute;
                    display: inline-block;
                    background-repeat: no-repeat;
                    height: 48px;
                    width: 91px;
                    left: -32px;
                    top: -15px;
                    margin-left: 2rem;
                    background-image: url("../img/assets/icon-service-blau-fallback.png");
                    background-size: contain;
                    @supports (color: black){
                      background-image: url("../img/assets/icon-service-blau.svg");
                    }
                    @media (min-width: $viewport-l){
                      height: 102px;
                      width: 102px;
                      left: 0;
                      top: -38px;
                      margin-left: 0;
                    }
                  }
                }
                &.drehen {
                  padding-left: 60px;
                  margin-top: 2rem;
                  @media (min-width: $viewport-l){
                    padding-left: 110px;
                  }
                  &:before {
                    content: "";
                    position: absolute;
                    display: inline-block;
                    background-repeat: no-repeat;
                    height: 48px;
                    width: 91px;
                    left: -32px;
                    top: -15px;
                    margin-left: 2rem;
                    background-image: url("../img/assets/icon-drehen-blau-fallback.png");
                    background-size: contain;
                    @supports (color: black){
                      background-image: url("../img/assets/icon-drehen-blau.svg");
                    }
                    @media (min-width: $viewport-l){
                      height: 102px;
                      width: 102px;
                      left: -19px;
                      top: -40px;
                      margin-left: 0;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .swiper-pagination {
      display: block;
      position: relative;
      .swiper-pagination-bullet {
        border-radius: 0;
        background-color: $darken-secondary;
        opacity: 1;
        margin: $swiper-bullet-margin;
      }
      .swiper-pagination-bullet-active {
        background-color: $primary;
      }
    }
    .services-swiper-button-next, .services-swiper-button-prev {
      display: none;
      @media (min-width: 1670px){
        display: block !important;
      }
    }
  }
}
///// Content Index /////
.content-index-container {
  top: 0px;
  width: 100%;
  z-index: 3;
  .measure {
    max-width: 100%;
  }
  .content-index {
    position: relative;
    z-index: 1;
    @include primaryGradient;
    &:after {
      display: block;
      content: "";
      position: absolute;
      height: 100%;
      width: 100%;
      @include screenEffect(0.2);
    }
    .content-anchor {
      display: block;
    }
    .tile {
      z-index: 10;
      position: relative;
      &:after {
        display: block;
        content: "";
        right: 36px;
        top: 40px;
        position: absolute;
        height: 13px;
        width: 13px;
        border-top: 1px solid $white;
        border-right: 1px solid $white;
        -webkit-transform: rotate(45deg);
                transform: rotate(45deg);
      }
      .inner {
        position: relative;
        padding: 2rem 2rem 2rem 0;
        @media (min-width: $viewport-xl){
          padding: 2rem;
        }
        .anchor {
          color: $white;
          position: relative;
          display: inline-block;
          margin: 0;
          text-transform: uppercase;
          z-index: 5;
          &#schleifen-anchor {
            top: -2px;
            left: 114px;
          }
          &#verchromen-anchor {
            top: -2px;
            left: 114px;
          }
          &#vernickeln-anchor {
            top: -2px;
            left: 114px;
          }
          &#finishing-anchor {
            top: -2px;
            left: 114px;
          }
          &#service-anchor {
            top: -2px;
            left: 114px;
          }
          &#drehen-anchor {
            top: -2px;
            left: 114px;
          }
        }
        .img {
          position: absolute;
          display: inline-block;
          width: auto;
          &#icon-schleifen {
            top: 32px;
            height: 41px;
            width: 110px;
            padding-left: 26px;
            padding-right: 17px;
          }
          &#icon-verchromen {
            top: 29px;
            height: 33px;
            width: 92px;
            padding-left: 48px;
          }
          &#icon-vernickeln {
            top: 32px;
            height: 33px;
            width: 84px;
            padding-left: 48px;
          }
          &#icon-finishing {
            height: 34px;
            width: 92px;
            padding-left: 30px;
          }
          &#icon-service {
            top: 25px;
            height: 48px;
            width: 91px;
            padding-left: 45px;
          }
          &#icon-drehen {
            top: 25px;
            height: 48px;
            width: 87px;
            padding-left: 36px;
          }
        }
      }
    }
  }
}
///// Content Modul /////
#section-2 {
  .text {
    padding-left: 0;
    padding-right: 0;
    @media (min-width: $viewport-l){
      padding-left: 80px;
      padding-right: 15px;
    }
    @media (min-width: $viewport-xl){
      padding-right: 205px;
    }
  }
}
.content-container {
  position: relative;
  .add-gradient {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0 auto;
    background-image: url("../img/assets/verlauf.png");
    background-repeat: no-repeat;
    background-position: center;
    height: 24px;
    width: 100%;
    max-width: 1205px;
  }
  .measure {
    max-width: 1205px;
    position: relative;
    @media (max-width: 1265px){
      padding: 0 30px 0 30px;
    }
    &.width-955px {
      max-width: 955px;
    }
    .checklist {
      &.std-padding {
        padding-top: 40px;
        padding-bottom: 40px;
      }
      li {
        position: relative;
        padding-top: 7px;
        padding-bottom: 7px;
        padding-left: 30px;
        font-size: 22px;
        color: $primary;
        font-weight: bold;
        &:before {
          content: "";
          position: absolute;
          left: 10px;
          top: 14px;
          display: inline-block;
          border-bottom: 2px solid $primary;
          border-right: 2px solid $primary;
          height: 19px;
          width: 8px;
          -webkit-transform: rotate(35deg);
                  transform: rotate(35deg);
        }
      }
    }

    .scroll-to-anchor {
      display: none;
    }
    .lightbox-gallery {
      margin-top: 45px;
      margin-bottom: 45px;
      .lightbox-collapse {
        div:not(:first-child){
          display: none;
          @media (min-width: $viewport-l){
            display: block;
          }
        }
      }
      img {
        display: block;
        margin-right: 1rem;
        margin-bottom: 1rem;
        width: 100%;
        @media (min-width: $viewport-l){
          width: 95%;
        }

      }
      h3 {
        margin-top: 0;
      }
      .icon-enlarge {
        position: relative;
        &:after {
          position: absolute;
          content: "";
          @include enlargeIcon();
          right: 14px;
          @media (min-width: $viewport-l){
            right: 28px;
          }
        }
      }
    }
    h3 {
      width: 100%;
      text-transform: uppercase;
      margin-top: $content-margin;
      margin-bottom: 24px;
      &.text-block {
        margin-top: 50px;
        font-size: 1.75rem;
      }
    }
    .more-infos-button {
      @include infoButton($primary, $white, $white);
    }
    .button-wrapper {
      display: none;
      width: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      margin: 25px 0 25px 0;
    }
    .certificate-logos {
      height: 83px;
      width: auto;
    }
    .certificate-container {
      padding-bottom: 1.8rem;
      &:not(:last-child) {
        border-bottom: 1px solid $secondary;
      }
      &:not(:first-child){
        padding-top: 2rem;
      }
      .text {
        padding: 0;
        font-size: 17px;
        margin-top: 1.3rem;
        @media (min-width: $viewport-l){
          padding: 0 5.3rem 0 2.75rem;
        }
        @media (min-width: $viewport-xl){
          margin-top: 0;
        }
      }
      .icon-enlarge {
        position: relative;
        display: table;
        margin: 0 auto;
        &#tuev-zertifikat {
          &:after {
            right: -8px;
            bottom: 7px;
          }
        }
        &:after {
          content: "";
          position: absolute;
          @include enlargeIcon;
          right: 3px;
        }
      }
      .img-wrapper {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
      }
    }
  }
}
.checklist-container {
  margin-top: 50px;
  margin-bottom: 13px;
}

///// Map container /////
  .map-container {
    background-color: $primary;
    margin-top: 42px;
    .content-container {
      h3 {
        color: $white;
        margin-top: 30px;
      }
      address {
        font-style: normal;
        p {
          color: $white;
          font-size: 17px;
          margin: 3px 0 0 0;
        }
        .heading {
          margin: 25px 0 5px 0;
          text-transform: uppercase;
          &:nth-child(2) {
            margin-top: 30px;
          }
        }
      }
      .more-infos-button {
        @include infoButton($white, $primary, $primary)
      }
    }
  }
///// Leistungen Navigation /////
.services-navigation-container {
  position: relative;
  .screen-effect {
    position: absolute;
    height: 100%;
    width: 100%;
    @include screenEffect(0.2);
  }
  .gradient {
    position: absolute;
    height: 100%;
    width: 100%;
    @include primaryGradient;
  }
  .measure {
    position: relative;
    max-width: 955px;
    h3 {
      display: none;
      text-transform: uppercase;
      color: $white;
      @media (max-width: 1265px){
        padding: 0 30px 0 30px;
      }
    }
  }
  .content-index-container {
    position: relative;
    .content-index {
      background: $transparent;
      &:after {
        display: none;
      }
      .tile {
        padding: 0;
        .inner {
          padding: 2rem 2rem 2rem 0;
          .img {
            position: absolute;
            display: inline-block;
            width: auto;
            &#icon-schleifen {
              top: 32px;
            }
            &#icon-verchromen {
              top: 29px;
            }
            &#icon-vernickeln {
              top: 32px;
            }
            &#icon-service {
              top: 25px;
            }
            &#icon-drehen {
              top: 25px;
            }
          }
        }
      }
    }
  }
}
///// Accordion /////
.accordion-container {
  .job-offer {
    .head {
      position: relative;
      background-color: $primary;
      border: 0;
      border-top: 1px solid $secondary;
      width: 100%;
      outline: 0;
      padding-left: 46px;
      -webkit-transition: background-color 0.3s;
      transition: background-color 0.3s;
      h4 {
        font-size: 22px;
        margin-bottom: 0;
        margin-top: 1rem;
        line-height: 1.12;
        .sub {
          font-size: 18px;
        }
      }
      p {
        margin-top: 5px;
        margin-bottom: 1rem;
      }
      &.js-active-accordion {
        background-color: $transparent;
        -webkit-transition: background-color 0.3s;
        transition: background-color 0.3s;
        h4, p {
          color: $primary;
        }
        &:before {
          -webkit-transform: rotate(-45deg);
                  transform: rotate(-45deg);
          -webkit-transition: -webkit-transform 0.3s;
          transition: -webkit-transform 0.3s;
          transition: transform 0.3s;
          transition: transform 0.3s, -webkit-transform 0.3s;
          border-color: $primary;
        }
      }
      &:before {
        content: "";
        position: absolute;
        left: 17px;
        top: 51px;
        bottom: 0;
        margin: 0 auto;
        display: inline-block;
        height: 12px;
        width: 12px;
        border-top: 2px solid $white;
        border-right: 2px solid $white;
        -webkit-transform: rotate(45deg);
                transform: rotate(45deg);
        -webkit-transition: -webkit-transform 0.3s;
        transition: -webkit-transform 0.3s;
        transition: transform 0.3s;
        transition: transform 0.3s, -webkit-transform 0.3s;
      }
      h4, p {
        color: $white;
        text-align: left;
      }
    }
    .accordion-desc {
      margin-left: 45px;
      display: none;
      .content-chunk {
        margin: 40px 0;
        &:first-child {
          margin-top: 0;
        }
        p {
          font-size: 17px;
          margin-bottom: 10px;
        }
        .list-bullets {
          li {
            color: $primary;
            background: url("../img/assets/list-bullet-square-fallback.png") no-repeat left 11px;
            padding-left: 12px;
            background-size: 5px 5px;
            @supports (color: black) {
              background: url("../img/assets/list-bullet-square.svg") no-repeat left 11px;
              background-size: 5px 5px;
            }
            a {
              color: $primary;
            }
          }
        }
      }
    }
  }
}
///// Kontaktpersonen /////
.contacts-wrapper {
  .grd-row {
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    @media (min-width: $viewport-l){
      -webkit-box-pack: start;
          -ms-flex-pack: start;
              justify-content: flex-start;
    }
  }
  .contact-person-container {
    position: relative;
    padding-bottom: 112px;
    margin-bottom: 64px;
    max-width: 100%;
    width: 100%;
    border-right: 1px solid $secondary;
    padding-right: 2rem;
    -webkit-hyphens: none;
    -ms-hyphens: none;
    hyphens: none;
    @media (min-width: $viewport-m){
      max-width: 45%;
    }
    @media (min-width: $viewport-l){
      padding-bottom: 25%;
      max-width: 25%;
      &:nth-child(4n + 4){
        border-right: 0;
      }
      &:not(:first-child){
        padding-left: 2rem;
      }
      &:not(:nth-child(5n + 5)){
        padding-left: 2rem;
      }
      &:first-child {
        padding-left: 0rem;
        max-width: 21%;
      }
      &:nth-child(5n + 5) {
        padding-left: 0rem;
        max-width: 21%;
      }
      &:nth-child(9n + 9){
        max-width: 21%;
        padding-left: 0rem;
      }
      &:nth-child(10n + 10){
        max-width: 25%;
        padding-left: 2rem;
      }
      &:nth-child(13n + 13){
        max-width: 21%;
        padding-left: 0rem;
      }
      &:nth-child(15n + 15){
        max-width: 25%;
        padding-left: 2rem;
      }
    }
    @media (min-width: 900px){
      padding-bottom: 12%;
    }
    .tel-container {
      position: absolute;
      bottom: 45px;
    }
    p {
      margin: 0;
      font-size: 17px;
      line-height: 1.5;
    }
    .button-wrapper {
      position: absolute;
      bottom: 0;
      margin-bottom: 0;
      margin-left: 0 !important;
      -webkit-box-pack: start !important;
          -ms-flex-pack: start !important;
              justify-content: flex-start !important;
    }
    a {
      color: $primary;
    }
  }
}
////// Map Kontaktseite /////
.content-container {
  .large-contact-map-container {
    .inner {
      background-color: $primary;
      padding: 46px 0 59px 0;
      position: relative;
      .measure {
        margin-bottom: 0 !important;
        h3 {
          color: $white;
          font-size: 28px;
          margin-top: 0;
        }
        address {
          p {
            color: $white;
            font-size: 17px;
          }
          a {
            color: $white;
          }
        }
        .scroll-to-anchor {
          position: absolute;
          top: auto;
          bottom: -5rem;
        }
      }
    }
    .g-map {
      position: relative;
      height: 585px;
      .add-gradient {
        top: auto;
      }
    }
  }
  #map-wrapper {
    &.measure {
      @media (min-width: $viewport-l){
        padding: 0;
      }
      @media (min-width: $viewport-xl){
        margin: 0;
        max-width: 100%;
        padding: 0;
      }
    }
    #scroll-button-map-sm {
      left: auto;
      right: 30%;
    }
    .text-container {
      padding: 0;
      @media (min-width: $viewport-l){
        padding: 0 0 0 85px;
      }
      @media (min-width: $viewport-xxl){
        padding: 0 285px 0 85px;
      }
      .text-wrapper {
        @media (max-width: 1919px){
          -ms-flex-preferred-size: 100%;
              flex-basis: 100%;
          max-width: 100%;
        }
      }
      h3 {
        position: absolute;
        top: 0;
        width: auto;
        font-size: 30px;
        margin-top: 28px;
        margin-bottom: 0;
        @media (min-width: $viewport-l){
          position: initial;
          font-size: 45px;
        }
      }
      p {
        line-height: 1.4;
        &.heading {
          font-weight: bold;
        }
      }
    }
    .small-contact-map-container {
      height: 51vw;
      width: 100%;
      min-height: 166px;
      margin-top: 5rem;
      @media (min-width: $viewport-l){
        margin: 0;
        height: 100%;
      }
    }
  }
}
///// Footer /////
footer {
  display: block;
  margin-top: 2rem;
  border-top: 1px solid $secondary;
  .link-list {
    border-bottom: 1px solid $secondary;
  }
  a {
    color: $primary;
    font-size: 14px;
    &:active {
      color: $primary;
    }
    &.hover {
      color: $secondary;
    }
  }
  ul {
    display: inline-block;
    width: 100%;
    &:nth-child(2) {
      display: block;
    }
    li {
      display: inline-block;
      margin-left: 10px;
    }
    .bullet {
      position: relative;
      &:before {
        content: "";
        position: absolute;
        height: 7px;
        width: 7px;
        top: 11px;
        left: -13px;
        background-image: url("../img/assets/bullet-fallback.png");
        background-repeat: no-repeat;
        @supports(color: black){
          background-image: url("../img/assets/bullet.svg");
        }
      }
    }
    .group {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      width: 100%;
      li {
        margin-left: 35px;
      }
    }
  }
  .logo {
    margin: 0 auto;
    margin-top: 15px;
    margin-bottom: 15px;
    height: 40px;
    width: 252px;
    display: block
  }
  .logo-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .button-wrapper {
    display: none;
  }
}
///// Media Queries /////
@media (min-width: $viewport-m) {
  ///// Hero Swiper /////////
  .hero-swiper-container {
    .swiper-wrapper {
      .swiper-slide {
        .measure {
          .text-container {
            z-index: 10;
            // &:after {
            //   content: "";
            //   position: absolute;
            //   bottom: -42px;
            //   left: 46px;
            //   height: 26px;
            //   width: 23px;
            //   border-top: 2px solid $white;
            //   border-right: 2px solid $white;
            // }
            h1 {
              font-size: 37px;
              @media (min-width: 657px){
                font-size: 51px;
              }
              @media (min-width: $viewport-l){
                font-size: 54px;
              }
              @media (min-width: 915px){
                font-size: 64px;
              }
              margin-bottom: 0;
            }
            p {
              font-size: 20px;
            }
          }
        }
      }
    }
  }
}
@media (min-width: $viewport-l) {
  ///// Defaults /////
  main {
    margin-top: 94px;
  }
  .menu-active-tab {
    display: inline-block;
    padding-bottom: 3px;
    width: auto;
    border-bottom: 6px solid $white;
  }
  ///// Navigation /////
  nav {
    height: 94px;
    .navigation-wrapper {
      position: relative;
      height: 100%;
      width: 100vw;
    }
    .outer {
      height: 94px;
    }
    .measure {
      width: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
    }
    .inner {
      background-color: rgba(0, 0, 0, 0);
      padding-bottom: 0;
      position: absolute;
      bottom: 3px;
      left: 0;
      width: 100%;
      display: none;
      @media (min-width: $viewport-l){
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
      }
      .menu-wrapper {
        position: absolute;
        top: -105px;
        right: 0;
        height: auto;
        overflow: visible;
      }
      #main-navigation {
        padding-left: 0;
        padding-right: 40px;
        li {
          display: inline-block;
          &:not(:last-child) {
            margin-right: 28px;
            -webkit-transition: all $nav-transition-time;
            transition: all $nav-transition-time;
            @media (min-width: 820px){
              margin-right: 40px;
            }
            @media (min-width: 915px){
              margin-right: 43px;
            }
          }
          a {
            font-weight: 600;
            padding-left: 0;
          }
          .anchor-arrow {
            &:before {
              display: none;
            }
          }
          .sub-menu {
            position: absolute;
            background-color: $primary;
            padding-bottom: 20px;
            padding-top: 20px;
            padding-left: 20px;
            outline: 1px solid $primary;
            .menu-active-li {
              &::before {
                opacity: 1;
              }
            }
            .menu-active-tab {
              color: $white;
              border: 0;
              li:before {
                opacity: 1;
              }
            }
            li {
              display: block;
              margin-right: 35px;
              &:hover {
                &:before {
                  opacity: 1;
                  transition: opacity 0.1s;
                }
              }
              &:before {
                content: "";
                display: inline-block;
                height: 11px;
                width: 19px;
                opacity: 0.7;
                margin-right: 10px;
                background-image: url("../img/assets/nav-arrow-right-fallback.png");
                background-repeat: no-repeat;
                @supports (color: black) {
                  background-image: url("../img/assets/nav-arrow-right.svg");
                }
              }
            }
            a {
              color: darken( $secondary, 10% );
              text-transform: capitalize;
            }
          }
        }
      }
    }
    #lang-picker {
      position: absolute;
      top: 0;
      right: 0;
      padding-right: 40px;
    }
    .hamburger {
      display: none;
    }
    .logo {
      left: 0;
      height: 95px;
      width: 130px;
    }
  }
  ///// Unterseiten Header /////
  .main-header {
    background-color: $light-grey;
    height: 50vw;
    .measure {
      padding-top: 5.5rem;
      max-width: 1160px;
      top: 0;
      .text-container {
        position: relative;
        // bottom: 0;
        margin: 0 auto;
        z-index: 1;
        top: 40%;
        transform: translate(0,-50%);
        // @media (max-width: $viewport-xl){
        //   top: -33px;
        // }
      }
      img {
        top: 1.8rem;
        height: 64px;
        &#icon-verchromen {
          top: 1.8rem;
          height: 64px;
          @media (min-width: $viewport-l){
            top: 0.8rem;
          }
          @media (min-width: 915px){
            top: 1.8rem;
          }
        }
        &#icon-vernickeln {
          top: 1.8rem;
          height: 64px;
          @media (min-width: $viewport-l){
            top: 0.8rem;
          }
          @media (min-width: 915px){
            top: 1.8rem;
          }
        }
        &#icon-drehen {
          left: -23px;
          width: 122px;
          height: 114px;
          top: -15px;
          @media (min-width: $viewport-l){
            top: -23px;
          }
          @media (min-width: 915px){
            top: 0rem;
          }
        }
        &#icon-finishing {
          height: 61px;
          width: 114px;
          left: -13px;
          @media (min-width: $viewport-l){
            top: 0.8rem;
          }
          @media (min-width: 915px){
            top: 1.8rem;
          }
        }
        &#icon-service {
          height: 102px;
          width: 102px;
          top: 0;
          @media (min-width: $viewport-l){
            top: 0.8rem;
          }
          @media (min-width: 915px){
            top: 1.8rem;
          }
        }
        &#icon-schleifen {
          height: 90px;
          width: 145px;
          left: -36px;
          top: 0;
          @media (min-width: $viewport-l){
            top: 0.8rem;
          }
          @media (min-width: 915px){
            top: 1.8rem;
          }
        }
      }
      h1 {
        font-size: 5.3125rem;
        padding-left: 8rem;
        &.finishing {
          padding-left: 10rem;
        }
        &.service {
          padding-left: 10rem;
        }
        &.drehen {
          padding-left: 10rem;
        }
      }
      p {
        font-size: 1.5625rem;
        top: 5.5rem;
        max-width: 520px;
        hyphens: none;
      }
    }
    picture {
      position: absolute;
      left: auto;
      right: 0;
      top: 0;
      &#header-finishing {
        top: -130px;
        right: -270px;
        @media (min-width: 1920px){
          top: 0;
          right: 0px;
        }
      }
      img {
        padding: 0;
        position: relative;
      }
    }
  }
  ///// Hero Swiper /////////
  .hero-swiper-container {
    &:before {
      content: "";
      position: absolute;
      top: 0;
      z-index: 2;
      @include transperantPrimaryGradient(rgba(30,99,150,5), 100%);
    }
    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      z-index: 2;
      -webkit-transform: rotate(180deg);
              transform: rotate(180deg);
      @include transperantPrimaryGradient(rgba(30,99,150,7), 85%);
    }
    .swiper-wrapper {
      .swiper-slide {
        background-color: #EBECED;
        .measure {
          .text-container {
            // top: 16%;
            padding: 0 96px;
            z-index: 3;
            p {
              font-size: 31px;
            }
            h1 {
              position: relative;
              &:after {
                content: "";
                position: absolute;
                bottom: -40px;
                left: -42px;
                height: 26px;
                width: 23px;
                border-top: 2px solid $white;
                border-right: 2px solid $white;
              }
            }
          }
        }
      }
    }
    .swiper-pagination {
      display: none;
    }
    .hero-swiper-button-next {
      display: none;
      right: 70px;
      z-index: 55;
      @include swiperButton(162px, $transparent-white, 55%, $arrow-right-white, "../img/assets/arrow-right-white-fallback.png", $transparent);
    }
    .hero-swiper-button-prev {
      display: none;
      left: 70px;
      z-index: 55;
      @include swiperButton(162px, $transparent-white, 45%, $arrow-left-white, "../img/assets/arrow-left-white-fallback.png", $transparent);
    }
    picture {
      max-width: 100%;
    }
  }
  ///// Services Slider /////
  .services-main-container {
    overflow-x: visible;
    .services-swiper-container {
      z-index: 2;
      .swiper-wrapper {
        .swiper-slide {
          height: 418px;
          .measure-outer {
            width: 100%;
            .measure {
              max-width: 1205px;
              position: absolute;
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              -webkit-box-align: center;
                  -ms-flex-align: center;
                      align-items: center;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              width: 100%;
              height: 100%;
              z-index: 3;
              @media (min-width: 1205px){
                padding: 0;
              }
              .text-container {
                .button-wrapper {
                  -webkit-box-pack: start;
                      -ms-flex-pack: start;
                          justify-content: flex-start;
                  margin: 0;
                }
              }
              h3 {
                position: relative;
                margin-top: 0;
                font-size: 45px;
              }
              p {
                font-size: 1.5rem;
                line-height: 1.5;
                width: 60%;
                &.reduce-m-top {
                  margin-top: 0.8rem;
                }
              }
            }
          }
          .gradient {
            //display: none;
            background: -webkit-gradient(linear, right top, left top, from(rgba(2,0,36,0)), color-stop(32%, rgba(235,236,237,1)));
            background: linear-gradient(270deg, rgba(2,0,36,0) 0%, rgba(235,236,237,1) 32%);
            width: 70%;
            height: 100%;
            position: absolute;
            z-index: 1;
            //IE and EDGE Hack
            @supports (-ms-ime-align: auto) {
              display: block;
            }
            @media all and (-ms-high-contrast:none) {
              display: block;
            }
          }
          &.compat-object-fit {
            background-size: auto 55vw;
            @media (min-width: $viewport-xl){
              background-size: auto 40vw;
            }
            @media (min-width: $viewport-xxl){
              background-size: auto 35vw;
              background-position: top -90px right;
            }
            background-position: right;
            background-repeat: no-repeat;
            img {
              opacity: 0;
            }
          }
          .bg-img-container {
            img {
              position: relative;
              //right: -130px;
              height: 100%;
              width: 100%;
              -o-object-fit: cover;
                 object-fit: cover;
              -o-object-position: right;
                 object-position: right;
            }
          }
        }
      }
      .swiper-pagination {
        display: block;
        position: absolute;
        @media (min-width: 1670px){
          display: none;
        }
      }
      .services-swiper-button-next, .services-swiper-button-prev {
        @include centerSwiperButtons;
        display: none;
      }
      .services-swiper-button-next {
        right: 70px;
        @include swiperButton(162px, $transparent-primary, 55%, $arrow-right-primary, "../img/assets/arrow-right-blue-fallback.png", $transparent-white);
      }
      .services-swiper-button-prev {
        left: 70px;
        @include swiperButton(162px, $transparent-primary, 45%, $arrow-left-primary, "../img/assets/arrow-left-blue-fallback.png", $transparent-white);
      }
    }
  }
  ///// Content Index /////
  .content-index-container {
    .content-index {
      .tile {
        &:after {
          left: 85%;
        }
      }
    }
  }
  ///// Leistungen Navigation /////
  .services-navigation-container {
    padding-top: 1rem;
    .measure {
      h3 {
        display: block;
      }
    }
    .content-index-container {
      position: relative;
      padding-bottom: 23px;
      .content-index {
        border: 1px solid rgba($secondary, 0.2);
        .tile {
          &:not(:nth-child(3)):not(:last-child){
            border-right: 1px solid rgba($secondary, 0.2);
          }
          &:first-child, &:nth-child(2), &:nth-child(3) {
            border-bottom: 1px solid rgba($secondary, 0.2);
          }
          &:after {
            -webkit-transform: rotate(0deg);
                    transform: rotate(0deg);
            top: 20px;
            left: 90%;
          }
        }
      }
    }
  }
  ///// Content Modul /////
  .content-container {
    &.index-page {
      h3 {
        font-size: 31px;
        @media (min-width: $viewport-l){
          font-size: 45px;
        }
      }
    }
    .measure {
      margin-bottom: $content-margin;
      .scroll-to-anchor {
        display: inline-block;
        @include scrollToAnchorButton(20px, 12px);
        position: absolute;
        top: -23px;
        left: 160px;
        z-index: 1;
        &.center {
          left: 0;
          right: 0;
          margin: 0 auto;
        }
      }
      p {
        margin-top: 0;
        &.padding-l-80px {
          padding-left: 80px;
        }
      }
      h3 {
        margin-bottom: 20px;
      }
      .button-wrapper {
        width: auto;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: left;
            -ms-flex-pack: left;
                justify-content: left;
        margin: 25px 0 0 80px;
      }
      .certificate-logos {
        height: 112px;
      }
    }
  }
  // Map container //
  .map-container {
    margin-top: 0px;
    .content-container {
      .measure {
        #scroll-button-map {
          right: 160px;
          left: auto;
        }
      }
      #map-wrapper {
        margin-bottom: 0;
        .text-container {
          margin: 100px 0;
        }
        .button-wrapper {
          margin-left: 0;
        }
        h3 {
          margin: 0;
          position: relative;
        }
        p {
          padding-left: 0;
          .heading {
            &:first-child {
              margin-top: 0;
            }
          }
        }
      }
    }
  }
  ///// Footer /////
  footer {
    margin-top: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    max-width: 100%;
    border-top: 0;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    padding-bottom: 20px;
    @media (max-width: $viewport-xl){
      padding: 0px;
    }
    .full-width {
      width: 100%;
      .link-list {
        @media (max-width: $viewport-xl){
          padding: 0;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          max-width: 100%;
          width: 100%;
          padding-right: 30px;
          margin-top: 15px;
          padding-bottom: 15px;
          border-bottom: 1px solid $secondary;
        }
      }
    }
    .logo-wrapper {
      @media (max-width: $viewport-xl){
        margin: 1px;
        width: 100%;
        max-width: 100%;
      }
      .logo {
        height: 36px;
        width: 240px;
        @media (max-width: $viewport-xl){
          margin-bottom: 30px;
          margin-top: 15px;
          margin-left: auto;
          margin-right: auto;
          display: block;
        }
      }
    }
    .link-list {
      border-bottom: none;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-direction: row;
              flex-direction: row;
      -webkit-box-pack: justify;
          -ms-flex-pack: justify;
              justify-content: space-between;
      padding: 22px 20.5% 0 115px;
    }
    ul {
      width: auto;
      &:nth-child(2) {
        display: inline-block;
      }
      .group {
        width: auto;
        display: inline-block;
      }
    }
    .scroll-to-anchor {
      position: relative;
      height: 34px;
      width: 34px;
      background-color: $white;
      border-radius: 50%;
      border: 1px solid $primary;
      outline: 0;
      margin: 35px 105px 35px 0;
      &:after {
        content: "";
        position: absolute;
        top: 9px;
        left: 0;
        right: 0;
        margin: 0 auto;
        height: 14px;
        width: 10px;
        display: inline-block;
        background-size: 9px 14px;
        -webkit-transform: rotate(180deg);
                transform: rotate(180deg);
        background-image: url("../img/assets/arrow-down-blue-fallback.png");
        -webkit-transition: 0.1s ease-in-out;
        transition: 0.1s ease-in-out;
        @supports (color: black) {
          background-image: url("../img/assets/arrow-down-blue.svg");
        }
      }
      &:hover {
        &:after {
          -webkit-transform: rotate(180deg) translateY(2px);
                  transform: rotate(180deg) translateY(2px);
          -webkit-transition: 0.3s ease-in-out;
          transition: 0.3s ease-in-out;
        }
      }
    }
    .button-wrapper {
      display: block;
      width: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack:end;
          -ms-flex-pack:end;
              justify-content:flex-end;
      border-bottom: 1px solid $secondary;
    }
  }
}
// Anpassungen neue Bilder 2021
@media (min-width: $viewport-l) and (max-width: $viewport-xxl) {
  .services-main-container .services-swiper-container .swiper-wrapper .swiper-slide .bg-img-container img {
    &#slide-walzenverarbeitung, &#slide-finish {
      right: -180px;
    }
    &#slide-schleifen {
      right: -210px;
    }
    &#slide-verchromen, &#slide-vernickeln {
      right: -100px;
    }
    &#slide-service {
      right: 0;
    }
    &#slide-drehen {
      right: -130px;
    }
  }
}

@media (min-width: $viewport-xl) {
  ///// Navigation /////
  nav {
    .inner {
      .menu-wrapper {
        width: 100%;
      }
      #main-navigation {
        display: table;
        margin: 0 auto;
        padding-left: 0;
        padding-right: 0px;
        .contain-sub-menu {
          &:hover {
            .sub-menu {
              display: block !important;
            }
          }
        }
      }
    }
    #lang-picker {
      position: absolute;
      top: 0;
      right: 12px;
      padding-right: 40px;
    }
  }
  ///// Hero Swiper //////
  .hero-swiper-container {
    .swiper-wrapper {
      .swiper-slide {
        .measure {
          .text-container {
            padding: 0 85px;
            p {

              @media (min-width: $viewport-xxl){
                position: absolute;
                left: 921px;
                width: 290px;
              }
            }
          }
        }
      }
    }
    @media (min-width: 1670px){
      .hero-swiper-button-next, .hero-swiper-button-prev {
        display: inline-block;
      }
      .hero-swiper-pagination {
        display: none;
      }
    }
    .swiper-pagination {
      display: none;
    }
    picture {
      max-width: 100%;
    }
  }
  ///// Content Index /////
  .content-index-container {
    position: absolute;
    top: 0;
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
    z-index: 3;
    .measure {
      max-width: 1205px;
    }
    .content-index {
      position: relative;
      background: none;
      &:after {
        display: none;
        content: "";
        position: absolute;
        height: 100%;
        width: 100%;
        @include screenEffect(0.2);
      }
      .tile {
        padding: 0;
        height: 167px;
        position: relative;
        border-left: 1px solid $secondary;
        &:first-child {
          @media (max-width: 1205px){
            border-left: 0;
          }
        }
        &:last-child {
          border-right: 1px solid $secondary;
          @media (max-width: 1205px){
            border-right: 0;
          }
        }
        &:after {
          display: inline-block;
          top: 12px;
          -webkit-transform: rotate(0deg);
                  transform: rotate(0deg);
          right: 12px;
          left: auto;
        }
        .inner {
          height: 100%;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: vertical;
          -webkit-box-direction: reverse;
              -ms-flex-direction: column-reverse;
                  flex-direction: column-reverse;
          -webkit-box-pack: center;
              -ms-flex-pack: center;
                  justify-content: center;
          -webkit-box-align: center;
              -ms-flex-align: center;
                  align-items: center;
          position: relative;
          .anchor {
            color: $white;
            position: relative;
            text-align: center;
            text-transform: uppercase;
            z-index: 5;
            &#schleifen-anchor {
              top: -8px;
              left: 0;
            }
            &#verchromen-anchor {
              top: -10px;
              left: 0;
            }
            &#vernickeln-anchor {
              top: -10px;
              left: 0;
            }
            &#finishing-anchor {
              top: -12px;
              left: 0;
            }
            &#service-anchor {
              top: -2px;
              left: 0;
            }
            &#drehen-anchor {
              top: -2px;
              left: 0;
            }
          }
          .img {
            position: relative;
            display: block;
            &#icon-schleifen {
              top: 0;
              margin-right: 30px;
              width: 92px;
              height: 57px;
              padding: 0;
            }
            &#icon-verchromen {
              top: 0;
              height: 50px;
              width: 67px;
              padding: 0;
            }
            &#icon-vernickeln {
              top: 0;
              height: 50px;
              width: 50px;
              padding: 0;
            }
            &#icon-finishing {
              height: 46px;
              width: 87px;
              padding: 0;
              margin-right: 6px;
            }
            &#icon-service {
              top: 2px;
              height: 64px;
              width: 64px;
              padding: 0;
            }
            &#icon-drehen {
              top: 0;
              height: 73px;
              width: 78px;
              padding: 0;
            }
          }
        }
      }
    }
  }
  ///// Leistungen Navigation /////
  .services-navigation-container {
    .content-index-container {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      .measure {
        max-width: 955px;
      }
      .content-index {
        position: relative;
        &:after {
          display: none;
        }
        .tile {
          // padding: 2rem 2rem 2rem 0;
          position: relative;
          max-width: 33.3333333333%;
          -ms-flex-preferred-size: 33.3333333333%;
              flex-basis: 33.3333333333%;
          border: 0;
          height: auto;
          &:after {
            display: inline-block;
            content: "";
            right: 36px;
            top: 20px;
            position: absolute;
            -webkit-transform: rotate(0deg);
                    transform: rotate(0deg);
            height: 13px;
            width: 13px;
            border-top: 1px solid $white;
            border-right: 1px solid $white;
          }
          .inner {
            position: relative;
            display: block;
            padding: 2rem 2rem 2rem 0;
            .anchor {
              color: $white;
              position: relative;
              display: inline-block;
              margin: 0;
              text-transform: uppercase;
              z-index: 5;
              &#schleifen-anchor {
                top: 0;
                left: 114px;
              }
              &#verchromen-anchor {
                top: 0;
                left: 114px;
              }
              &#vernickeln-anchor {
                top: 0;
                left: 114px;
              }
              &#finishing-anchor {
                top: 0;
                left: 114px;
              }
              &#service-anchor {
                top: 0;
                left: 114px;
              }
              &#drehen-anchor {
                top: 0;
                left: 114px;
              }
            }
            .img {
              position: absolute;
              display: inline-block;
              width: auto;
              &#icon-schleifen {
                top: 32px;
                height: 41px;
                width: 110px;
                padding-left: 26px;
                padding-right: 17px;
              }
              &#icon-verchromen {
                top: 29px;
                height: 33px;
                width: 92px;
                padding-left: 48px;
              }
              &#icon-vernickeln {
                top: 32px;
                height: 33px;
                width: 84px;
                padding-left: 48px;
              }
              &#icon-finishing {
                height: 34px;
                width: 92px;
                padding-left: 30px;
              }
              &#icon-service {
                top: 25px;
                margin-top: 0;
                height: 48px;
                width: 91px;
                padding-left: 45px;
              }
              &#icon-drehen {
                top: 25px;
                height: 48px;
                width: 87px;
                margin-top: 0;
                padding-left: 36px;
              }
            }
          }
        }
      }
    }
  }
  .img-grd {
    .measure {
      h1 {
        font-size: 55px;
      }
      p {
        font-size: 25px;
        margin-top: 0.5rem;
      }
    }
  }
  ///// Hero Swiper /////////
  .hero-swiper-container {
    max-height: 786px;
    picture {
      position: relative;
      // top: 0;
      min-width: 100%;
      // left: 0;
      // right: 0;
      // bottom: 0;
      // margin: 0 auto;
      img {
        display: block;
        width: 100%;
      }
    }
  }
}
@media (min-width: $viewport-xxl) {
  nav {
    .logo {
      left: 114px;
      height: 142px;
      width: 197px;
      overflow: hidden;
      padding: 5px 13px;
      .mobile {
        display: none;
      }
      .desktop {
        display: block;
        width: 85px;
        height: 105px;
      }
    }
  }
  ///// Hero Swiper /////////
  .hero-swiper-container {
    height: 780px;
    .swiper-wrapper {
      .swiper-slide {
        .measure {
          .text-container {
            // top: 141px;
            h1 {
              font-size: 85px;
            }
          }
        }
      }
    }
    picture {
      img {
        display: block;
        width: 100%;
      }
    }
  }
  .services-main-container {
    .services-swiper-container {
      .swiper-wrapper {
        .swiper-slide {
          picture {
            img {
              -o-object-fit: none;
                 object-fit: none;
              font-family: 'object-fit: none;';
            }
          }
        }
      }
    }
  }
}
