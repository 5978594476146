// From: http://philipwalton.github.io/solved-by-flexbox/demos/media-object/

.media {
  display: flex;
  align-items: flex-start;
}

.media .media-figure {
  margin-right: 1em;
}

.media .media-body {
  flex: 1 0 0;
}
