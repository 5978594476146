.bg--red        { background-color: $red; }
.bg--orange     { background-color: $orange; }
.bg--blue       { background-color: $blue; }
.bg--green      { background-color: $green; }
.bg--white      { background-color: $white; }
.bg--light-gray { background-color: $light-gray; }
.bg--mid-gray   { background-color: $mid-gray; }
.bg--dark-gray  { background-color: $dark-gray; }
.bg--off-white  { background-color: $off-white; }

.fnt--red        { color: $red; }
.fnt--orange     { color: $orange; }
.fnt--blue       { color: $blue; }
.fnt--green      { color: $green; }
.fnt--white      { color: $white; }
.fnt--light-gray { color: $light-gray; }
.fnt--mid-gray   { color: $mid-gray; }
.fnt--dark-gray  { color: $dark-gray; }
.fnt--off-white  { color: $off-white; }
