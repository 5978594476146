$viewport-m: 32rem !default; //512px
$viewport-l: 48rem !default; //768px
$viewport-xl: 64rem !default; //1024px
$viewport-xxl: 90rem !default; //1440px

$font-size: 16px !default;
$font-weight: 300 !default;
$font-color: #333 !default;

$font-family:  "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, sans-serif !default;
$monospace-font-family: "Monaco", "Lucida Console", Courier, monospace !default;

// Sass will automatically convert the rgba values to hex
// if they alpha value is 1. So, it will default to .99 to
// avoid this.
$alpha-value: .99;

$gray-light-5: rgba(#d8d8d8, $alpha-value);
$gray-light-8: rgba(#fafafa, $alpha-value);
$gray-mid-6: rgba(#909090, $alpha-value);
$gray-dark-9: rgba(#484848, $alpha-value);

$red: #e74c3c !default;
$orange: #f39c12 !default;
$blue: #3498db !default;
$green: #25ba84 !default;
$off-white: $gray-light-8 !default;
$light-gray: $gray-light-5 !default;
$mid-gray: $gray-mid-6 !default;
$dark-gray: $gray-dark-9 !default;
$white: #fff !default;

$link-color: $blue !default;

$border-radius: 3px !default;

$measure-width: 48rem !default;
