$margin: 1rem;

.m0  { margin: 0; }
.mx0 { margin-left: 0; margin-right: 0; }
.my0 { margin-top: 0; margin-bottom: 0; }

.m1  { margin: $margin; }
.mx1 { margin-left: $margin; margin-right: $margin; }
.my1 { margin-top: $margin; margin-bottom: $margin; }

.m2  { margin: $margin*2; }
.mx2 { margin-left: $margin*2; margin-right: $margin*2; }
.my2 { margin-top: $margin*2; margin-bottom: $margin*2; }
