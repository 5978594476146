$padding: 1rem;

.p0  { padding: 0; }
.px0 { padding-left: 0; padding-right: 0; }
.py0 { padding-top: 0; padding-bottom: 0; }

.p1  { padding: $padding; }
.px1 { padding-left: $padding; padding-right: $padding; }
.py1 { padding-top: $padding; padding-bottom: $padding; }

.p2  { padding: $padding*2; }
.px2 { padding-left: $padding*2; padding-right: $padding*2; }
.py2 { padding-top: $padding*2; padding-bottom: $padding*2; }
